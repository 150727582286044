@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family: 'veneer_cleanreg';
    src: url('../fonts/fontsfree-net-veneer-clean-reg-regular-webfont.woff2') format('woff2'),
        url('../fonts/fontsfree-net-veneer-clean-reg-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Roboto+Condensed:wght@700&display=swap');
:root {
    --primary: #0072CE;
    --body-text: #1A1616;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #BDBDBD;
    --dark-blue: #064e89;
    --light-blue: #e5f3ff;
    --grey: #828282;
    --grey2: #4F4F4F;
    --grey3: #F5F5F5
}


body {
    margin: 0;
    padding: 0;
    border: 0;
    color: var(--body-text);
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
}

a {
    color: var(--primary);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s ease;
}

a:hover {
    text-decoration: underline;
}

::not(.angular-editor-textarea)  ul {
    margin: 0;
    padding: 0;
}

::not(.angular-editor-textarea) li {
    list-style: none;
}
.h1,h1,
.h2,h2
,h3,.h3,
h4,.h4,
h5,.h5
,h6,.h6{
    font-family: 'Roboto Condensed', sans-serif;
}
h1 {
    font-size: 2.2em;
}

h2 {
    font-size: 2.0em;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.6em;
}

h5 {
    font-size: 1.4em;
}

p {
    font-size: 1.2em;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}
.form-control{font-size: 0.90rem;}
// font-sizes
.font-veneerbold {
    font-family: 'veneer_cleanreg';
}
.fs-11 {
    font-size: 0.70rem;
}
  .fs-12 {
    font-size: 0.75rem;
}
.fs-13 {
    font-size: 0.8rem;
}
.fs-14 {
    font-size: 0.85rem;
}

.fs-15 {
    font-size: 0.94rem;
}

.fs-18 {
    font-size: 1.15rem !important;
}
.fs-20 {
    font-size: 1.25rem !important;
}
.fs-22 {
    font-size: 1.4rem !important;
}

.fs-60 {
    font-size: 3.7rem
}
.bg-grey {
    background-color: var(--grey3);
}

.text-grey {
    color: var(--bs-gray);
}

.opacity-1 {
    opacity: 0.1;
}

.btn {
    font-family: "Inter", sans-serif;
    min-width: 120px;
    padding: 7px 18px;
    font-size: 0.9rem;
    font-weight: 600;
}

.btn-primary {
    background-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:active {
    background-color: var(--dark-blue) !important;
}

.btn-outline-primary {
    background-color: transparent !important;
    color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: var(--bs-white);
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
}

.btn-outline-default {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
}

.btn-outline-default:hover,
.btn-outline-default:active {
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
    color: #ffffff;
}

.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid var(--body-text);
    color: var(--body-text);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
    background-color: var(--dark-blue) !important;
    border: 1px solid var(--dark-blue) !important;
    color: var(--bs-white);
}

.mw-240 {
    min-width: 240px !important;
}

.mxw-1130 {
    max-width: 1130px;
}

.bg-black {
    background: var(--body-text);
}

.z-index-1 {
    z-index: 1;
}

.z-index-10 {
    z-index: 10;
}
 
// input checkbox
.cursor-pointer {
    cursor: pointer;
}

.form-control:focus,
.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.outline-red {
    border-color: red;
}

.danger {
    font-size: 14px;
    color: red;
}

.shadow-lg-custom {
    // box-shadow: 0 1rem 3rem rgba(0,0,0,.105)!important;
    box-shadow: 2px 2px 32px rgba(44, 41, 41, 0.09);
}

.border-grey-custom {
    border: 1px solid #e7e3e3;
}

// badges
.badge-primary {
    color: #fff;
    background-color: var(--primary);
    
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
    padding: 6px 10px;
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}
 .btn-outline-success {
    color: #28a745;
    background-color:transparent;
    border-color: #28a745;
}
.btn-outline-success:hover {
    color: #ffffff !important;
    background-color:#28a745 !important; 
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;  
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-light {
    color: #212529;
    background-color: #dbdddf;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

.left-0{left: 0;}
.right-0{right: 0;}
.top-0{top: 0;}
.bottom-0{bottom: 0;}